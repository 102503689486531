import React from "react";
import { navigate } from "gatsby";
import "./Stores.scss";

const Stores = () => {
  const handleFirstCityClick = () => {
    // localStorage.setItem("city", "jonkoping");
    navigate("/");
  };

  // const handleSecondCityClick = () => {
  //   localStorage.setItem("city", "linkoping");
  //   navigate("/linkoping/");
  // };

  // const scrollToCities = () => {
  // 	const cities = document.querySelector('.list');

  // 	cities.scrollIntoView({ behavior: "smooth", block: "center" });
  // }

  return (
    <div className="Stores max-w-7xl mx-auto">
      <div className="top">
        <div className="left">
          <div className="heading">Här finns Putso nära dig</div>
          <div className="description">
            Välkommen till ett Putso nära dig. Vi erbjuder fönsterputsning på
            olika orter. Klicka på våra lokala regionkontor nedan för att läsa
            mer om fönsterputsning på din ort.
          </div>
          {/* <button className="stores-btn" onClick={scrollToCities}>Här finns vi</button> */}
        </div>
        {/* <div className="right">
          <Icon />
        </div> */}
      </div>
      <div className="list">
        <div className="list-item">
          <div class="name">
            <h3>Jönköping</h3>
          </div>
          <div className="info">
            <a href="tel:036141500">036-141500</a>
            <a href="mailto:info@putso.nu">info@putso.nu</a>
          </div>
          <div className="buttons">
            <button className="stores-btn" onClick={handleFirstCityClick}>
              Till Kontorssidan
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stores;
